import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const JobCard = ({ job }) => {
  const navigate = useNavigate();
  if (!job) return <p>Ei avoimia paikkoja</p>;

  const endDate = new Date(job.endDate).toLocaleDateString();

  return (
    <div
      key={job.id}
      id="jobCard"
      onClick={() => {
        window.scrollTo(0, 0);
        setTimeout(() => navigate(`/employee/${job.id}`), 10); // Delay navigation slightly
      }}
      className="primary mb-2 p-3 rounded h-100 d-flex flex-column"
    >
      <h3 style={{ flexGrow: 1 }}>{job.name}</h3>
      <p>{job.locations}</p>
      <p> Haku päättyy: {endDate}</p>
      <Row className="mt-auto">
        <Col>
          <Button
            onClick={event => {
              event.stopPropagation(); // Prevent the click from bubbling up to the div
              window.open(`https://tehoa.likeit.fi/jsp/duuni/Application.jsp?id=0&register=1&advertId=${job.id}`);
            }}
          >
            Hae paikkaa
          </Button>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">Lue lisää</Col>
      </Row>
    </div>
  );
};

export default JobCard;
