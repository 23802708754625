import { Link } from 'react-router-dom';
import { Row, Col, Image, Navbar, Nav, Container } from 'react-bootstrap';
import './NavBar.css';
import React, { useState, useEffect } from 'react';
import logo from '../../resources/images/tehoa-logo.jpg';

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(false);
  };

  const handleScroll = () => {
    if (expanded) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [expanded]);

  return (
    <div className="overflow-x-disable w-100 nav-row">
      <div className="container">
        <Row>
          <Col xs={{ span: 8, order: 2 }} sm={{ span: 4, order: 1 }}>
            <Nav.Link as={Link} to="/">
              <Image className="nav-logo" src={logo} />
            </Nav.Link>
          </Col>
          <Col xs={{ span: 4, order: 1 }} sm={{ span: 8, order: 2 }} className="d-flex align-items-center">
            <Navbar
              expanded={expanded}
              onToggle={() => setExpanded(!expanded)}
              collapseOnSelect
              className="nav-bar"
              expand="md"
            >
              <Container>
                <Navbar.Brand href="#home"></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="custom-toggler" />
                <Navbar.Collapse>
                  <Nav className="me-auto menu-toggle">
                    <Nav.Link className="nav-button" as={Link} to="/" onClick={handleToggle}>
                      Etusivu
                    </Nav.Link>
                    <Nav.Link className="nav-button" as={Link} to="/employee" onClick={handleToggle}>
                      Työntekijälle
                    </Nav.Link>
                    <Nav.Link className="nav-button" as={Link} to="/employer" onClick={handleToggle}>
                      Työnantajalle
                    </Nav.Link>
                    <Nav.Link
                      className="nav-button"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://tehoa.likeit.fi/"
                      onClick={handleToggle}
                    >
                      Kirjaudu
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NavBar;
