import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Button, Form, Container } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import './EmployerView.css';

export default function EmployerView() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const reCaptchaRef = useRef();

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let token = null;
    try {
      token = await reCaptchaRef.current.executeAsync();
    } catch (error) {}

    if (!token) return;

    emailjs.init(process.env.REACT_APP_MAIL_PUBLIC_KEY);
    emailjs.sendForm(process.env.REACT_APP_MAIL_SERVICE_ID, process.env.REACT_APP_MAIL_TEMPLATE_ID, e.target).then(
      () => {
        toast.success('Yhteydenottosipyyntösi on lähetetty', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
        });
        // Reset form and reCAPTCHA
        setFormData({ name: '', email: '', message: '' });
        reCaptchaRef.current.reset();
      },
      error => {
        console.error('Error sending email:', error.text);
        toast.error('Virhe sähköpostin lähettämisessä', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
        });
      }
    );
  };

  return (
    <Container className="container-flex">
      <Row className="form-container container-primary">
        <h1 className="text-center">Jätä yhteydenottopyyntö</h1>
        <Row className="justify-content-center mb-4">
          <Form onSubmit={handleSubmit} className="w-75">
            <Form.Group controlId="name">
              <Form.Label>Nimi:</Form.Label>
              <Form.Control
                className="textfield"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Sähköposti:</Form.Label>
              <Form.Control
                className="textfield"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label>Viesti:</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                className="textfield"
                required
              />
            </Form.Group>
            <ReCAPTCHA
              ref={reCaptchaRef}
              sitekey={process.env.REACT_APP_CAPTCHA_INVISIBLE_KEY}
              size="invisible" // Use invisible reCAPTCHA
            ></ReCAPTCHA>
            <Button type="submit" className="mt-2 button-primary">
              Lähetä
            </Button>
          </Form>
        </Row>
      </Row>
    </Container>
  );
}

export function EmployerHeroView() {
  return (
    <Container className="hero-image gap-3">
      <Col xs={12} md={6} className="mt-4">
        <Row>
          <h1>Työnantaja</h1>
          <p>
            Jos etsit luotettavaa ja asiantuntevaa kumppania henkilöstöasioissasi ravintola- tai kaupan alalla, Tehoa
            Henkilöstöpalvelut on oikea valinta.
          </p>
          <p>Ota meihin yhteyttä ja keskustellaan, kuinka voimme sinua saavuttamaan tavoitteesi.</p>
        </Row>
      </Col>
      <Col xs={12} md={6} className="mt-4">
        <h1>Tehoa henkilöstöpalvelut Oy</h1>
        <Row>
          <span>Kauppakatu 20, 70100 Kuopio</span>
          <span>marko@tehoahp.fi</span>
          <span>+358 400761399</span>
          <span>Marko Melamies</span>
        </Row>

        <Row className="mt-4">
          <h3>Verkkolaskutustiedot</h3>
          <span>Verkkolaskuosoite: 003734345525</span>
          <span>Välittäjän tunnus: 003723327487</span>
          <span>Välittäjä: Apix Messaging Oy</span>
        </Row>
      </Col>
    </Container>
  );
}
