// src/features/dataSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchData = createAsyncThunk('data/fetchData', async () => {
  const { convertXML } = require('simple-xml-to-json');

  const response = await convertXML(
    (
      await axios.get(
        'https://tehoa.likeit.fi/servlet/XML?handler=duuni.Advert&state=-1&loginuser=guest&loginpass=vieras&lang=-1&cors=zWw4M5IVG43T',
        {
          headers: {
            Accept: 'application/xml',
          },
        }
      )
    ).data
  );

  const jobs = response?.list?.children;
  const parsedJobs = jobs.map(({ advert }) => {
    const parsedJob = {};
    advert.children.forEach(item => {
      const key = Object.keys(item)[0];
      const value = item[key].content;
      parsedJob[key] = value;
    });
    return parsedJob;
  });

  return parsedJobs;
});

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchData.pending, state => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default dataSlice.reducer;
