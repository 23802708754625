import React from 'react';
import { Row, Container } from 'react-bootstrap';
import './EmployerView.css';

export default function PrivacyView() {
  return (
    <Container className="container-flex">
      <Row className="container-privacy">
        <h1>Rekisteri- ja tietosuojaseloste</h1>
        <p>
          Tämä on Tehoa Henkilöstöpalvelut Oy:n EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja
          tietosuojaseloste.
        </p>
        <p>Laadittu 15.4.2024. Viimeisin muutos 23.10.2024.</p>
        <h2>Rekisterinpitäjä</h2>
        <p>
          Tehoa Henkilöstöpalvelut Oy
          <br />
          Y- 3434552-5
          <br />
          Kauppakatu 20
          <br />
          70100 Kuopio
        </p>
        <h2>Rekisteristä vastaava yhteyshenkilö</h2>
        <p>
          Marko Melamies
          <br />
          <a href="mailto:marko@tehoahp.fi">marko@tehoahp.fi</a>
          <br />
          0400 761399
        </p>
        <p>Rekisterikohtaisena vastuuhenkilönä voi toimia myös joku muu yhteyshenkilö kuin edellä mainittu.</p>
        <h3>3. Rekisterin nimi</h3>
        <p>Rekisteri- ja tietosuojaseloste</p>
        <h3>4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h3>
        <p>EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on:</p>
        <ul>
          <li>henkilön suostumus (dokumentoitu, vapaaehtoinen, yksilöity, tietoinen ja yksiselitteinen)</li>
          <li>sopimus, jossa rekisteröity on osapuolena</li>
          <li>rekisterinpitäjän oikeutettu etu (esim. asiakassuhde ennen sopimusta, työsuhde, jäsenyys)</li>
        </ul>
        <p>
          Henkilötietojen käsittelyn tarkoitus on yhteydenotto asiakkaisiin, asiakassuhteen ylläpito, markkinointi,
          viestintä ja liiketoiminnan kehittäminen.
          <br />
          Tietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin.
        </p>
        <h3>5. Rekisterin tietosisältö</h3>
        <p>
          Rekisteriin tallennettavia tietoja ovat: henkilön nimi, asema, yritys / organisaatio, yhteystiedot,
          puhelinnumero, sähköpostiosoite, osoitetiedot, www-sivujen osoitteet, verkkoyhteyden IP-osoite, tunnukset /
          profiilit sosiaalisen median palveluissa, tiedot tilatuista palveluista ja niiden muutoksista, laskutustiedot,
          muut asiakassuhteeseen ja tilattuihin palveluihin liittyvät tiedot.
        </p>
        <p>
          Tehoa Henkilöstöpalvelut Oy:llä on useita rekistereitä kuten mm tietosuojaseloste työnhakijalle,
          tietosuojaseloste työntekijälle, tietosuojaseloste Asiakkaalle.
        </p>
        <p>
          Tehoa Henkilöstöpalvelut Oy noudattaa tietojen säilyttämisessä voimassa olevan lainsäädännön mukaisia
          velvoitteita. Tietojen säilyttämisajoista kerrotaan jokaisen rekisterin tietosuojaselosteissa.
        </p>
        <p>
          Verkkosivuston vierailijoiden IP-osoitteita ja palvelun toiminnoille välttämättömiä evästeitä käsitellään
          oikeutetun edun perusteella mm. tietoturvasta huolehtimiseksi ja sivuston vierailijoiden tilastotietojen
          keruuta varten niissä tapauksissa, kun niiden voidaan katsoa olevan henkilötietoja. Kolmansien osapuolten
          evästeille pyydetään tarvittaessa suostumus erikseen.
        </p>
        <h3>6. Säännönmukaiset tietolähteet</h3>
        <p>
          Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. www-lomakkeilla lähetetyistä viesteistä,
          sähköpostitse, puhelimitse, sosiaalisen median palvelujen kautta, sopimuksista, asiakastapaamisista ja muista
          tilanteista, joissa asiakas luovuttaa tietojaan.
        </p>
        <p>
          Yritysten ja muiden organisaatioiden yhteyshenkilöiden tietoja voidaan kerätä myös julkisista lähteistä kuten
          verkkosivuilta, hakemistopalveluista ja muilta yrityksiltä.
        </p>
        <h3>7. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle</h3>
        <p>
          Tehoa Henkilöstöpalvelut Oy pyrkii palveluiden toteuttamisen ja henkilötietojen käsittelemisen toteuttamaan
          ainoastaan EU- tai ETA-alueella sijaitsevia palveluita ja toimintoja hyödyntäen. Kuitenkin voi olla joitakin
          yksittäistapauksia, joissa voidaan joutua käyttämään myös muualla sijaitsevia toimijoita, palveluja ja
          palvelimia.
        </p>
        <p>
          Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja voidaan julkaista siltä osin kuin niin on
          sovittu asiakkaan kanssa. Tietoja luovutetaan ainoastaan lain tai sopimuksen sallimissa rajoissa.
        </p>
        <p>
          Tietoja ei siirretä rekisterinpitäjän toimesta EU:n tai ETA:n ulkopuolelle ilman oikeudellisia perusteita.
        </p>
        <h3>8. Rekisterin suojauksen periaatteet</h3>
        <p>
          Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla käsiteltävät tiedot suojataan
          asianmukaisesti. Kun rekisteritietoja säilytetään internet-palvelimilla, niiden laitteiston fyysisestä ja
          digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti. Rekisterinpitäjä huolehtii siitä, että
          tallennettuja tietoja sekä palvelimen käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta
          kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden työnkuvaan
          se kuuluu.
        </p>
        <h3>9. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h3>
        <p>
          Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia
          mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa
          tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää kirjallisesti
          rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan
          henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa
          (pääsääntöisesti kuukauden kuluessa).
        </p>
        <h3>10. Muut henkilötietojen käsittelyyn liittyvät oikeudet</h3>
        <p>
          Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä
          ("oikeus tulla unohdetuksi"). Niin ikään rekisteröidyillä on muut EU:n yleisen tietosuoja-asetuksen mukaiset
          oikeudet kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa. Pyynnöt tulee lähettää
          kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan
          henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa
          (pääsääntöisesti kuukauden kuluessa).
        </p>
      </Row>
    </Container>
  );
}
