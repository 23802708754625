// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../resources/images/tehoa-hero-etusivu.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --bg: white;
  --text: white;
  --secondary: #96eaff;
  --primary: #2e5dff;
}
a {
  --bs-link-color: var(--text);
}

.front-hero-image {
  min-height: 50vh;
  height: auto;
  width: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  padding-bottom: 1rem;
}

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -91px;
}

.button-primary {
  background: var(--primary);
}

a {
  color: var(--secondary);
}

@media (max-width: 768px) {
  .front-hero-image {
    height: auto;
    width: 100%;
    background-size: cover;
    background-position: center;
    padding-bottom: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/common/styles/Main.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,oBAAoB;EACpB,kBAAkB;AACpB;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,yDAAsE;EACtE,sBAAsB;EACtB,2BAA2B;EAC3B,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,2BAA2B;IAC3B,oBAAoB;EACtB;AACF","sourcesContent":[":root {\n  --bg: white;\n  --text: white;\n  --secondary: #96eaff;\n  --primary: #2e5dff;\n}\na {\n  --bs-link-color: var(--text);\n}\n\n.front-hero-image {\n  min-height: 50vh;\n  height: auto;\n  width: 100%;\n  background-image: url(\"../../resources/images/tehoa-hero-etusivu.jpg\");\n  background-size: cover;\n  background-position: center;\n  padding-bottom: 1rem;\n}\n\n.hero-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  margin-top: -91px;\n}\n\n.button-primary {\n  background: var(--primary);\n}\n\na {\n  color: var(--secondary);\n}\n\n@media (max-width: 768px) {\n  .front-hero-image {\n    height: auto;\n    width: 100%;\n    background-size: cover;\n    background-position: center;\n    padding-bottom: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
