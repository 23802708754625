import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../../storage/dataSlice'; // Your action to fetch job data
import './EmployeeView.css';
import { Container, Row, Button } from 'react-bootstrap';

export default function EmployerJobView() {
  const { id } = useParams(); // Get the job ID from query parameters
  const dispatch = useDispatch();

  // Select job data from the Redux store
  const job = useSelector(state => state.data.items.find(i => i.id === id));

  useEffect(() => {
    if (id && !job) {
      dispatch(fetchData(id));
    }
  }, [id, dispatch, job]);

  // Check if job exists
  if (!job) {
    return <div>Työpaikkaa ei löytynyt</div>;
  }

  return (
    <Container className="container-flex job-card-container">
      <div
        className="primary rounded job-card"
        dangerouslySetInnerHTML={{
          __html: decodeHtmlEntities(job.description),
        }}
      />
      <Row className="mb-3">
        <Button
          onClick={() =>
            window.open(`https://tehoa.likeit.fi/jsp/duuni/Application.jsp?id=0&register=1&advertId=${job.id}`)
          }
        >
          Hae paikkaa
        </Button>
      </Row>
    </Container>
  );
}

function decodeHtmlEntities(str) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = str;
  return textArea.value;
}
