import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import JobCard from '../components/JobCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../storage/dataSlice';
import './EmployeeView.css';

export default function EmployerView() {
  const dispatch = useDispatch();
  const { items } = useSelector(state => state.data);
  const [filters, setFilters] = useState({ muncipalities: '', worktitle: '', fieldofwork: '' });
  const translations = {
    muncipalities: 'Kaikki sijainnit',
    worktitle: 'Kaikki työtehtävät',
    fieldofwork: 'Kaikki toimialat',
  };

  // Filter and paginate jobs
  const filteredJobs = items.filter(job =>
    Object.entries(filters).every(([key, value]) => job?.[key].toLowerCase().includes(value.toLowerCase()))
  );

  const handleFilterChange = e => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    if (!items || items.length === 0) dispatch(fetchData());
  }, [dispatch, items]);

  return (
    <Container className="container-flex">
      <div className="filters">
        {['muncipalities', 'worktitle', 'fieldofwork'].map((filter, index) => (
          <input
            key={index}
            name={filter}
            type="text"
            placeholder={`${translations[filter]}`}
            value={filters[filter]}
            onChange={handleFilterChange}
          />
        ))}
      </div>
      <Row className="mt-5 mb-5">
        <h1 className="heading">Avoimet työpaikat</h1>
        <div className="job-container">
          {filteredJobs.length !== 0 && filteredJobs.map(job => <JobCard job={job} />)}
        </div>
      </Row>
    </Container>
  );
}

export function EmployeeHeroView() {
  return (
    <Container className="hero-image">
      <Col xs={12} md={6} className="mt-4">
        <Row>
          <h1>Työntekijä</h1>
          <p>Oletko valmis liittymään energiseen tiimiimme, jossa työnteko on yhtä aikaa hauskaa ja palkitsevaa.</p>
          <p>
            Arvostamme hyviä tyyppejä, joilla on asenne kohdallaan. Olitpa sitten konkari tai urasi alkuvaiheessa,
            meillä on sinulle paikka!
          </p>
          <p>Lähetä hakemuksesi ja kerro meille, miksi juuri sinä olisit mahtava lisä meidän tiimiin.</p>
        </Row>
        <Col className="text-center">
          <Button
            onClick={() =>
              window.open('https://tehoa.likeit.fi/jsp/duuni/Application.jsp?id=0&register=1&advertId=92353', '_blank')
            }
          >
            Lähetä avoin hakemus tästä
          </Button>
        </Col>
      </Col>
    </Container>
  );
}
