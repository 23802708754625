import { Row, Col, Image } from 'react-bootstrap';
import logo from '../../resources/images/tehoa-logo.jpg';
import { Link } from 'react-router-dom';
import hela from '../../resources/images/hela-logo.png';
import luotettava from '../../resources/images/luotettava-logo.png';

const Footer = () => {
  return (
    <footer className="pt-4 primary">
      <Row>
        <Col className="text-center">
          <h3>
            <Image className="nav-logo" src={logo} />
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">Tehoa henkilöstöpalvelut Oy</Col>
      </Row>
      <Row>
        <Col className="text-center">Kauppakatu 20, 70100 Kuopio</Col>
      </Row>
      <Row>
        <Col className="text-center">marko@tehoahp.fi</Col>
      </Row>
      <Row>
        <Col className="text-center">+358 400761399</Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Link to="/privacy">Tietosuojaseloste</Link>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={6} md={3} className="text-center">
          <h5>Työnhakija</h5>
          <p>
            <Link to="/employee">Avoimet työpaikat</Link>
          </p>
          <p>
            <a
              href="https://tehoa.likeit.fi/jsp/duuni/Application.jsp?id=0&register=1&advertId=92353"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jätä hakemuksesi
            </a>
          </p>
        </Col>
        <Col xs={6} md={3} className="text-center">
          <h5>Työnantaja</h5>
          <p>
            <Link to="/employer">Jätä yhteydenottopyyntö</Link>
          </p>
        </Col>
        <Col xs={4} md={3} className="text-center">
          <h3>
            <Image className="nav-logo" src={hela} />
          </h3>
        </Col>
        <Col xs={8} md={3} className="text-center">
          <h3>
            <Image className="nav-logo" src={luotettava} />
          </h3>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="text-center">
          <p>
            <a target="_blank" rel="noopener noreferrer" href="https://tehoa.likeit.fi/jsp/duuni/PrivacyPolicy.jsp">
              Tietosuojaseloste
            </a>
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
