import NavBar from './components/navbar/NavBar';
import Footer from './components/footer/Footer';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home, { HomeHeroView } from './common/home';
import EmployeeView, { EmployeeHeroView } from './common/employee/EmployeeView';
import EmployeeJobView from './common/employee/EmployeeJobView';
import EmployerView, { EmployerHeroView } from './common/employer/EmployerView';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './common/styles/App.css';
import './common/styles/Main.css';
import PrivacyView from './common/employer/PrivacyView';

const NotFound = () => (
  <div className="text-center text-primary">
    <p>Sivua ei löydy</p>
  </div>
);

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <div className="main-route overflow-x-disable">
        <div className="front-hero-image">
          <NavBar />
          <Routes>
            <Route index element={<HomeHeroView />} />
            <Route path="/employer" element={<EmployerHeroView />} />
            <Route path="/employee" element={<EmployeeHeroView />} />
            <Route path="/*" element={<HomeHeroView />} />
          </Routes>
        </div>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/employee" element={<EmployeeView />} />
          <Route path="/employee/:id" element={<EmployeeJobView />} />
          <Route path="/employer" element={<EmployerView />} />
          <Route path="/privacy" element={<PrivacyView />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <ToastContainer />
        <Footer />
      </div>
    </div>
  );
}

export default App;
