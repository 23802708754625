import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Homeview.css';
import JobCard from '../components/JobCard';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../storage/dataSlice';

export default function HomeView() {
  const dispatch = useDispatch();
  const { items } = useSelector(state => state.data);

  useEffect(() => {
    if (!items || items.length === 0) dispatch(fetchData());
  }, [dispatch, items]);

  return (
    <Container>
      <Row className="mt-4">
        <Col md={7}>
          <div className="image-col-left" />
        </Col>
        <Col md={5} className="d-grid">
          <div className="text-col-right" style={{ width: 'fit-content' }}>
            <h3>Yritys</h3>
            <p>
              Palvelemme asiakkaitamme tarjoamalla joustavaa henkilöstövuokrausta, rekrytointipalveluja, sekä työvoiman
              hallintoa. Meillä on laaja verkosto ammattilaisia, jotka ovat valmiita työskentelemään eri tehtävissä
              ravintola- ja kaupan aloilla. Ymmärrämme alan erityispiirteet, sekä haasteet ja siksi pystymme tarjoamaan
              asiakkaillemme juuri heidän tarpeisiin räätälöityjä ratkaisuja.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="">
        <Col md={5} className="d-grid">
          <div className="text-col-left" style={{ zIndex: '1' }}>
            <h3>Palvelut</h3>
            <p>
              <strong>Henkilöstövuokraus:</strong> Tarjoamme asiantuntevia työntekijöitä lyhytaikaisiin ja
              pitkäaikaisiin tarpeisiin.
            </p>
            <p>
              <strong>Rekrytointi:</strong> Autamme asiakasyrityksiämme löytämään oikeat osaajat oikeisiin tehtäviin.
            </p>
            <p>
              <strong>Koulutus ja kehittäminen:</strong> Tarjoamme koulutusmahdollisuuksia työntekijöillemme
              varmistaaksemme heidän osaamisensa ja työllistettävyyden.
            </p>
          </div>
        </Col>
        <Col md={7}>
          <div className="image-col-right" />
          <div
            className="text-col-left"
            style={{
              marginTop: '-5rem',
              width: '70%',
            }}
          >
            <h3>Vahvuudet</h3>
            <p>
              <strong>Asiantuntemus:</strong> Meillä on syvällinen ymmärrys ravintola- ja kaupan alasta, mikä tekee
              meistä luotettavan kumppanin.
            </p>
            <p>
              <strong>Joustavuus:</strong> Pystymme reagoimaan nopeasti asiakkaidemme muuttuviin tarpeisiin.
            </p>
            <p>
              <strong>Verkosto:</strong> Laaja ammattilaisten verkosto takaa, että voimme tarjota asiakkaillemme parasta
              mahdollista osaamista
            </p>
          </div>
        </Col>
      </Row>

      <Row className="mb-4 lastpic">
        <Col md={7}>
          <div className="main-1 image-col-left image-3" />
        </Col>
        <Col md={5} className="d-grid">
          <div className="text-col-right middle-float">
            <h3>Missiomme ja visiomme</h3>
            <p>
              Missiomme on olla helposti lähestyttävä henkilöstöpalveluiden tarjoaja, joka on tunnettu erinomaisesta
              asiakaspalvelustamme ja työntekijöiden osaamisesta.
            </p>
            <p>
              Visioon kuuluu kasvaa ja laajentaa palvelujamme, tarjoten yhä enemmän vaihtoehtoja sekä asiakkaillemme
              että työntekijöillemme eri toimialoilla.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="">
        <Col md={6} className="d-grid">
          <div className="text-col-left w-100" style={{ marginTop: '8rem' }}>
            <h3>Arvot</h3>
            <strong>Luotettava ja rehellinen</strong>
            <ul>
              <li>Tunnemme toimialamme ja teemme mitä lupaamme.</li>
              <li>Teemme minkä lupaamme ja lupaamme auttaa kaikissa tilanteissa.</li>
            </ul>
            <strong>Ratkaisukeskeisyys</strong>
            <ul>
              <li>Halu auttaa on toimintamme kulmakivi.</li>
              <li>Näemme ratkaisuja emme ongelmia.</li>
            </ul>
            <strong>Rohkeus</strong>
            <ul>
              <li>Olemme avoimia muutokselle.</li>
              <li>Emme pelkää haastavia tilanteita.</li>
            </ul>
            <strong>Tehokkuus</strong>
            <ul>
              <li>Kestävä kasvuhalukkuus.</li>
              <li>Haluamme kasvaa kannattavasti ammattitaidosta tinkimättä.</li>
            </ul>
            <strong>Välittäminen</strong>
            <ul>
              <li>Välitämme niin työntekijöistämme kuin asiakkaistamme. </li>
              <li>Vaalimme pitkäaikaisia suhteita.</li>
            </ul>
          </div>
        </Col>
        <Col md={6}>
          <div className="image-col-right image-4" />
        </Col>
      </Row>

      <Row className="openJobsContainer">
        <h2 className="heading">Avoimet työpaikat</h2>
        <div className="job-container">{items.length !== 0 && items.map(job => <JobCard job={job} />)}</div>
      </Row>
    </Container>
  );
}

export function HomeHeroView() {
  const navigate = useNavigate();
  return (
    <Container className="heroView">
      <Col md={6} className="mt-4">
        <Row>
          <h1>Tehoa työelämään</h1>
          <p>
            Tehoa Henkilöstöpalvelut on dynaaminen henkilöstöpalveluyritys, jonka tavoitteena on yhdistää työntekijöiden
            osaaminen ja osaajien tarpeet asiakasyritystemme vaatimusten mukaan.
          </p>
          <p>Tarjoamme asiantuntevaa ja joustavaa henkilöstöpalvelua erityisesti ravintola- ja kaupan aloilla.</p>
        </Row>

        <Row className="mt-4">
          <Col className="text-center">
            <Button className="button" onClick={() => navigate('/employee')}>
              Etsin töitä
            </Button>
          </Col>
          <Col className="text-center">
            <Button className="button" onClick={() => navigate('/employer')}>
              Etsin työntekijää
            </Button>
          </Col>
        </Row>
      </Col>
    </Container>
  );
}
