// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-image {
  width: 75%;
  display: flex;
}

.container-primary {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary);
  gap: 3rem;
  padding: 1rem;
  margin-top: 1rem !important;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.container-privacy {
  display: flex;
  background-color: var(--primary);
  padding: 1rem;
  margin-top: 1rem !important;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .hero-image {
    height: auto;
    width: 100%;
    padding-bottom: 1rem;
    display: grid;
  }
}
`, "",{"version":3,"sources":["webpack://./src/common/employer/EmployerView.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gCAAgC;EAChC,SAAS;EACT,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;IACX,oBAAoB;IACpB,aAAa;EACf;AACF","sourcesContent":[".container-flex {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.hero-image {\n  width: 75%;\n  display: flex;\n}\n\n.container-primary {\n  display: flex;\n  justify-content: space-between;\n  background-color: var(--primary);\n  gap: 3rem;\n  padding: 1rem;\n  margin-top: 1rem !important;\n  border-radius: 1rem;\n  margin-bottom: 1rem;\n}\n\n.container-privacy {\n  display: flex;\n  background-color: var(--primary);\n  padding: 1rem;\n  margin-top: 1rem !important;\n  border-radius: 1rem;\n  margin-bottom: 1rem;\n}\n\n@media (max-width: 768px) {\n  .hero-image {\n    height: auto;\n    width: 100%;\n    padding-bottom: 1rem;\n    display: grid;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
