// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.employee-jobs {
  margin-top: -3rem !important;
  margin-bottom: 3rem;
  border-color: var(--primary);
  border: 0.1rem solid var(--primary);
  border-radius: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
}

.filters {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary);
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
}

.filters input {
  flex: 1 1; /* Make inputs equal width */
  padding: 10px;
  margin: 0 10px; /* Margin between inputs */
  border: 1px solid #ccc; /* Border around inputs */
  border-radius: 4px; /* Rounded corners for inputs */
}


.job-card {
  margin: 1rem;
  padding: 1rem;
}

@media (max-width: 768px) {
.filters {
  display: grid;
  justify-content: space-between;
  background-color: var(--primary);
  grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;

}
.job-card {
  margin: 0rem;
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.job-card-container {
  margin: 0rem !important;
  padding: 0rem !important;
}
}

`, "",{"version":3,"sources":["webpack://./src/common/employee/EmployeeView.css"],"names":[],"mappings":";AACA;EACE,4BAA4B;EAC5B,mBAAmB;EACnB,4BAA4B;EAC5B,mCAAmC;EACnC,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;EACpB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gCAAgC;EAChC,qCAAqC;EACrC,SAAS;EACT,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,SAAO,EAAE,4BAA4B;EACrC,aAAa;EACb,cAAc,EAAE,0BAA0B;EAC1C,sBAAsB,EAAE,yBAAyB;EACjD,kBAAkB,EAAE,+BAA+B;AACrD;;;AAGA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,gCAAgC;EAChC,qCAAqC;IACnC,WAAW;;AAEf;AACA;EACE,YAAY;EACZ,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;AACA","sourcesContent":["\n.employee-jobs {\n  margin-top: -3rem !important;\n  margin-bottom: 3rem;\n  border-color: var(--primary);\n  border: 0.1rem solid var(--primary);\n  border-radius: 1rem;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  background-color: white;\n}\n\n.filters {\n  display: flex;\n  justify-content: space-between;\n  background-color: var(--primary);\n  grid-template-columns: repeat(3, 1fr);\n  gap: 3rem;\n  padding: 1rem;\n  margin-top: 1rem;\n  border-radius: 1rem;\n}\n\n.filters input {\n  flex: 1; /* Make inputs equal width */\n  padding: 10px;\n  margin: 0 10px; /* Margin between inputs */\n  border: 1px solid #ccc; /* Border around inputs */\n  border-radius: 4px; /* Rounded corners for inputs */\n}\n\n\n.job-card {\n  margin: 1rem;\n  padding: 1rem;\n}\n\n@media (max-width: 768px) {\n.filters {\n  display: grid;\n  justify-content: space-between;\n  background-color: var(--primary);\n  grid-template-columns: repeat(1, 1fr);\n    gap: 0.5rem;\n\n}\n.job-card {\n  margin: 0rem;\n  padding: 1rem;\n  width: 100%;\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n}\n.job-card-container {\n  margin: 0rem !important;\n  padding: 0rem !important;\n}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
