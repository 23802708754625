// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background-color: white; /* Change this to match your app's background color */
  color: var(--text);
  font-family: Montserrat, regular;
  font-size: 1.1rem;
}

p {
  font-size: 1.1rem;
}

.primary {
  background-color: var(--primary);
}

.secondary {
  background-color: var(--secondary);
}

.App {
  overflow: hidden;
}

h1 {
    font-family: Schibsted Grotesk;
    font-weight: 700;
}

h2 {
    font-family: Schibsted Grotesk;
    font-weight: 600;
}

button {
    font-family: SCHIBSTED GROTESK;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 1.5rem !important;
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}

a {
  text-decoration: none;
  color: var(--secondary);
}
`, "",{"version":3,"sources":["webpack://./src/common/styles/App.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,uBAAuB,EAAE,qDAAqD;EAC9E,kBAAkB;EAClB,gCAAgC;EAChC,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;IACI,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,gBAAgB;IAChB,gCAAgC;IAChC,+BAA+B;IAC/B,4BAA4B;AAChC;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":[".App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\nbody {\n  background-color: white; /* Change this to match your app's background color */\n  color: var(--text);\n  font-family: Montserrat, regular;\n  font-size: 1.1rem;\n}\n\np {\n  font-size: 1.1rem;\n}\n\n.primary {\n  background-color: var(--primary);\n}\n\n.secondary {\n  background-color: var(--secondary);\n}\n\n.App {\n  overflow: hidden;\n}\n\nh1 {\n    font-family: Schibsted Grotesk;\n    font-weight: 700;\n}\n\nh2 {\n    font-family: Schibsted Grotesk;\n    font-weight: 600;\n}\n\nbutton {\n    font-family: SCHIBSTED GROTESK;\n    text-transform: uppercase;\n    font-weight: 700;\n    border-radius: 1.5rem !important;\n    padding-bottom: 1rem !important;\n    padding-top: 1rem !important;\n}\n\na {\n  text-decoration: none;\n  color: var(--secondary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
